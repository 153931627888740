@mixin size-small {
  width: 0.875rem;
  height: 0.875rem;
}

@mixin size-smaller {
  width: 0.75rem;
  height: 0.75rem;
}

@mixin size-smallest {
  width: 0.5rem;
  height: 0.5rem;
}

@mixin size-normal {
  width: 1rem;
  height: 1rem;
}

@mixin size-large {
  width: 1.875rem;
  height: 1.875rem;
}

@mixin size-larger {
  width: 2rem;
  height: 2rem;
}

@mixin size-largest {
  width: 2.25rem;
  height: 2.25rem;
}
