.icon {
  height: 1.25rem;
  width: 1.25rem;
  vertical-align: middle;

  use {
    fill: currentColor;
  }
}

.loader {
  height: 50%;
}

.FileDownloadLoader
{
  height: 100%;
}

.SubTableLoader
{
  height: 60px;
  width: auto;
}