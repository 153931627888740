.bold {
  font-weight: 600;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400
}

.italic {
  font-style: italic;
}

.caps {
  text-transform: uppercase;
}

.left-align {
  text-align: left;
}

.center {
  text-align: center;
}

.right-align {
  text-align: right;
}

.start-align {
  text-align: start;
}

.end-align {
  text-align: end;
}

.justify {
  text-align: justify;
}

.underline {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.break-word {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.display {
  @include pt-sans;
  font-weight: bold;
}

.small {
  @include small;
}

.smaller {
  @include smaller;
}

.large {
  @include large;
}

.larger {
  @include larger;
}

.cursor-pointer {
  cursor: pointer;
}

.date-separator {
  position: relative;
  text-align: center;
  border-bottom: 2px solid color(light-grey, 2);
  margin: 2rem 0; 

  > span {
    background-color: color(white);
    padding: 0 2rem;
    transform: translateY(50%);
    display: inline-block;
  }
}
