//colors
$color_gallery_approx: #eee;
$color_celeste_approx: #ccc;
$color_dodger_blue_approx: #2196f3;
$white:  white;
$red:red;
$lightgray:lightgray;


// .buttonDelete {
// 	background-color:red;
// 	border: none;
// 	color: white;
// 	padding: 20px;
// 	text-align: center;
// 	text-decoration: none;
// 	display: inline-block;
// 	font-size: 16px;
// 	margin: 4px 2px;
// }
// .buttonDeleteRadius {
// 	border-radius: 2px;
// }

// background-color: red;
//     border: none;
//     color: #fff;
//     padding: 0px;
//     padding: 0.6rem 0.75rem;
//     text-align: center;
//     text-decoration: none;
//     display: inline-block;
//     font-size: 16px;
//     font-weight: 700;
//     margin: 4px 2px;
//     width: 100px;
//     height: 34px;
//     height: 43.1875px;
//}
// For TFS Item 35480(Invitation Dashboard)
.buttonaccept {
    background-color: #62be76;
    border: none;
    color: white;
    padding: 0.6rem 0.75rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin: 4px 2px;
    /* width: 100px; */
    height: 34px;
    height: 43.1875px;
    cursor: pointer;
}
.buttondelete {
	background-color:$red;
	border: none;
	color:$white;
	// padding: 20px;
	padding: 0.6rem 0.75rem;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
    font-weight: 700;
	margin: 4px 2px;
    width: 100px;
    height: 34px;
	height: 43.1875px;
	cursor: pointer;

	&[disabled] {
		// opacity: 0.35;
		// cursor: default;
		// pointer-events: none;
		
		background-color: lightgray;
		/* opacity: .35; */
		cursor: default;
		pointer-events: none;
	  }
}
.buttondeleteradius {
	border-radius: 8px;
}

.marginlegalcheckbox
{
	margin-left: 30px;
}

.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: $color_gallery_approx;
	&:after {
		content: "";
		position: absolute;
		display: none;
	}
}
.labelcont {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	//Instead of the line below you could use @include user-select($select)
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:checked ~ .checkmark {
			background-color: $color_dodger_blue_approx;
			&:after {
				display: block;
			}
		}
	}
	// &:hover input ~ .checkmark {
	// 	background-color: $color_celeste_approx;
	// }
	.checkmark:after {
		left: 7px;
		top: 2px;
		width: 5px;
		height: 12px;
	
		// left: 9px;
		// top: 5px;
		// width: 5px;
		// height: 10px;
		border: solid $white;
		border-width: 0 3px 3px 0;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(45deg);
	}
}


