.thread {
  @include h5;
  width: 100%;
  margin: 0;
  color: color(black);

  &__row {
    display: flex;
    margin-top: 2.5rem;
  }

  &__avatar {
    flex: 0 1 0;
    min-width: 3.5rem;

    &-round {
      @include size-largest;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      border-radius: 100%;
      background-color: color(tan, 1);
      color: color(white);
    }
  }

  &__scrollchild {
    padding-top: 35px
  }

  &__name {
    @include h4;
    margin: 0 0.5rem 0 0;
  }

  &__last-update {
    @include h5;
    margin: 0 0 0 0.5rem;
    color: color(grey);
  }

  &__attachment {
    position: relative;

    &-content {
      @include flex(row, flex-start, center);
      width: 100%;
      background-color: color(tan);
      border-radius: 3px;
      padding: 0.75rem;
      border: 2px solid color(light-grey, 1);
      margin-bottom: 1rem;
      position: relative;
    }

    &-extension {
      font-size: 0.85rem;
      text-transform: uppercase;
      color: color(white);
      font-weight: 600;
      background-color: color(red, 1);
      border-radius: 4px;
      padding: 0.25rem;
      margin-right: 0.5rem;
    }

    &+.thread__comments-group {
      border: 2px solid color(light-grey, 1);
      border-top: none;
      border-radius: 0 0 3px 3px;
      padding: 0.25rem 0;
      margin-top: -1rem;
      background-color: color(white);

      .thread__comment {
        padding: 0.5rem 0.75rem
      }

    }

    &:hover .thread__item-remove {
      display: flex;
    }
  }

  &__comment {
    padding: 0.25rem 0;
    background-color: color(white);
    position: relative;

    &:hover .thread__item-remove {
      display: flex;
    }
  }

  &__physical-submittals {
    position: relative;

    &:hover .thread__item-remove {
      display: flex;
    }
  }

  &__item-remove {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    display: none;
    cursor: pointer;
    border-radius: 50%;
    font-size: 1.125rem;
    width: 20px;
    height: 20px;
    background-color: color(white);
    box-shadow: 0 0 7px color(light-grey, 2);
  }

  &__ps-label {
    @include pt-sans;
    font-weight: bold;
    font-size: 0.9rem;
    text-transform: uppercase;
  }

  &__comments-group {
    margin-bottom: 1rem;
  }

  &__status {
    position: relative;

    &-content {
      @include flex(column, center, stretch);
      margin-bottom: 1rem;
    }

    &-description {
      padding: 1rem 0.5rem;
      background-color: lighten(color(light-grey, 1), 5);
      text-align: center;
    }

    .label {
      text-align: center;
    }

    &:hover .thread__item-remove {
      display: flex;
    }
  }

  &__header {
    position: fixed;
    top: 14px;
    background-color: color(tan);
    color: color(black);
    height: 5rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    width: 100%;
    z-index: 1;

    @include breakpoint(md) {
      width: 65.6666667%;
      max-width: $slide-out-modal-sidebar-width;
    }




    @include breakpoint(xl) {
      max-width: 83.3333333%;
    }

    &-rowtop {
      padding-top: 30px !important;
      margin: 0 -0.75rem;
      padding: 0 2.5rem;

      .list__title {
        @include h4;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;

        @include breakpoint(sm) {
          text-align: left;
        }
      }

      .small {
        color: color(grey);
      }
    }

    &-row {
      margin: 0 -0.75rem;
      padding: 0 2.5rem;

      .list__title {
        @include h4;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;

        @include breakpoint(sm) {
          text-align: left;
        }
      }

      .small {
        color: color(grey);
      }
    }

    &-dot:before {
      content: '\b7';
      color: color(black);
      margin: 0 0.5rem;
    }
  }

  // CR#35118 select files to download
  &__fileDownloadToggleWidth {
    width: 330px;
  }

  &__fileDownloadWidth {
    width: 135px;
    line-height: 20px;
    font-size: 87.5%;
  }

  &__checkboxFileSelect {
    position: relative;
    top: 19px;
    left: -20px;

    input {
      width: 16px;
      height: 16px;
    }
  }


  &__content {
    height: 100%;
    margin: 5rem 0 2rem;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: color(tan);
    padding: 0.5em;
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.1);
    width: 100%;
    z-index: 2;

    @include breakpoint(md) {
      width: 66.6666667%;
      max-width: $slide-out-modal-sidebar-width;
    }

    @include breakpoint(xl) {
      max-width: 83.3333333%;
    }

    .button {
      flex-shrink: 0;
      white-space: nowrap;

      &[disabled] {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
      }
    }
  }

  &__update-status-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: color(brand-dark);
    padding: 1.5rem;
    color: color(white);

    &-close {
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
    }

    &-title {
      @include h2;
      margin: 0;
      width: 100%;
      text-align: center;
    }

    .button {
      margin: 1.25rem 0 0;
      color: black;
      width: 100%;
    }
  }

  &__dropzone--active {
    height: 100%;
    background-color: color(light-grey, 1);
    opacity: 0.6;
    border-left: 2px dashed color(grey);
    border-right: 2px dashed color(grey);
  }

  &__progress {
    width: 100%;
    height: 3.125rem;
    background-color: color(tan);
    padding: 0.5rem;
    margin-left: 3.5rem;
    border: 1px solid color(light-grey, 2);

    &-cover {
      width: 100%;
      height: 100%;
      background-color: color(white);
    }

    &-value {
      background-color: color(green);
      color: color(white);
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 1.25rem;
    }
  }

  a {
    color: color(blue);
    text-decoration: none;
  }

  &__posting-publicly {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(-100%);
    color: color(white);
    text-align: center;
    padding: 0.6rem 0 0.5rem;
    background: repeating-linear-gradient(-45deg,
        lighten(color(green), 10),
        lighten(color(green), 10) 5px,
        color(green) 5px,
        color(green) 15px);


    .icon {
      margin-right: 0.5rem;
      position: relative;
      top: -2px;
    }


    &.is-private {
      background: repeating-linear-gradient(-45deg,
          lighten(color(red, 1), 10),
          lighten(color(red, 1), 10) 5px,
          color(red, 1) 5px,
          color(red, 1) 15px);


    }

    >a {
      color: color(white);
      text-decoration: underline;
      font-weight: 600;
    }
  }

  &__item-content {
    position: relative;

    &.is-private {
      padding: 2rem 1rem 1rem 2rem;
      background: repeating-linear-gradient(-45deg,
          color(light-grey, 2),
          color(light-grey, 2) 3px,
          lighten(color(light-grey, 2), 5) 3px,
          lighten(color(light-grey, 2), 5) 15px);

      >.icon {
        position: absolute;
        top: 10px;
        left: 10px;
        color: color(black);
        width: 1rem;
        height: 1rem;
      }
    }
  }
  &__SelectedfiledownloadLoader
  {
   
  z-index: 1002;
  text-align: center;
  width: 150px;
  height: 75px;
  position:fixed; 
  top: calc(30% - 25px); // half of width
  left: calc(50% - 80px); // half of height
  vertical-align:middle;
   
   }


}


.add-correspondence .thread__dropzone {
  min-height: 200px;
  border: 1px dashed color(light-grey);
  cursor: pointer;
  padding: 1rem;
}

.add-correspondence .rdw-editor-main {
  min-height: 200px;
}

.thread__footer .rdw-editor-main {
  min-height: 95px;
}

.thread__footer .rdw-colorpicker-modal,
.rdw-dropdown-optionwrapper {
  top: -250px;

}


.printLink {
  color: #408ed6;
  text-decoration: underline;
}

#quick-parts {
  width: 100px;
}

#quick-parts-thread-footer {
  width: 200px;
}

.thread-upload-files {
  padding-bottom: 5px;
}

.thread-control-vertical-collection {
  margin-left: 1.5%;

}

.thread-Editor-Container {
  width: 76%;
}

.thread-button-add-comment {
  width: 2.25rem;
}

.thread-control-left-marign {
  margin-left: 1%;
}

#quick-parts-correspondence {
  width: 25vw;
}
