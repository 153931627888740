.left {
  float: left;
}

.right {
  float: right;
}

.clearfix {
  @include clearfix;
}
