.h-100 {
  height: 100%;
}

.min-h-100 {
  min-height: 100%;
}

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}


.w-md-15
{
  width: 15%;
}


.w-md-10
{
  width: 10%;
}

.w-md-5{
  width: 5%;
}