.toggle {
    display: inline-block;
    border-radius: 5px;
    border: 1px solid color(light-grey,1);
    overflow: hidden;
    width: 150px;

    > div {
        display: inline-block;
        border-right: 1px solid color(light-grey,1);

        &:last-child {
            border-right: none;
        }
    }

    input[type="radio"] {
        pointer-events: none;
        width: 0;
        height: 0;
        opacity: 0;
        position: absolute;

        & + label {
            display: block;
            cursor: pointer;
            color: color(black);
           // padding: 0.75rem 1.5rem;
           padding:.75rem .75rem;
            text-align: center;
        }

        &:checked + label {
            color: color(blue);
            background-color: color(white);
        }
    }



}