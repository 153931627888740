@use "sass:math";

@mixin clearfix {
  &:before,
  &:after {
    content: " ";
    display: table
  }

  &:after {
    clear: both
  }
}

/**
 * Flex shorthand
 */
@mixin flex ($direction: row, $justify: flex-start, $align: stretch) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}


/**
 * Apply a percentage-based width
 * @param  {number} $percentage
 */
@mixin make-width($percentage: 100) {
  width: percentage($percentage * 0.01);
}


/**
 * Apply a percentage-based left offset
 * @param  {number} $percentage
 */
@mixin make-left($percentage: 100) {
  left: percentage($percentage * 0.01);
}


/**
 * Apply a percentage-based right offset
 * @param  {number} $percentage
 */
@mixin make-right($percentage: 100) {
  right: percentage($percentage * 0.01);
}


/**
 * Full-coverage pseudo element
 */
@mixin cover-before {
 position: relative;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    @content;
  }
}

@mixin cover-after {
 position: relative;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    @content;
  }
}

/**
 * Mixin for creating intrinsic ratios in CSS
 */

@mixin fluid-aspect($ratio: 16 9, $selector: "> :first-child") {
  $selector: unquote($selector);

  padding-bottom: percentage(math.div(nth($ratio, 2), nth($ratio, 1)));
  position: relative;

  #{$selector} {
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
