@mixin z-0 {
  z-index: 0;
}

@mixin z-1 {
  z-index: 100;
}

@mixin z-2 {
  z-index: 200;
}

@mixin z-3 {
  z-index: 300;
}

@mixin z-4 {
  z-index: 400;
}

@mixin z-5 {
  z-index: 500;
}

@mixin z-6 {
  z-index: 600;
}

@mixin z-7 {
  z-index: 700;
}

@mixin z-8 {
  z-index: 800;
}

@mixin z-9 {
  z-index: 900;
}

@mixin z-10 {
  z-index: 1000;
}

@mixin z-11 {
  z-index: 1100;
}