.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;

  &--open {
    animation: overlayin 500ms;
    background: rgba(#000, 0.6);
    display: flex;
  }

  &--close {
    animation: overlayout 500ms;
    background: rgba(#000, 0);
  }

  &__body {
    width: 90%;
    max-width: 30em;
    max-height: 90%;
    background: #ffffff;
    animation: modalin 350ms;
    position: relative;

    &__content {
      padding: $gutter;
    }

    &__footer {
      padding: $gutter * 0.5;
    }

    .modal--close & {
      animation: modalout 350ms;
    }
  }

  &__close {
    position: absolute;
    right: (-$gutter * 0.5);
    top: (-$gutter * 0.5);
    padding: $gutter * 0.5;
    border-radius: 100%;
    background-color: color(yellow, 1);

    svg {
      pointer-events: none;
    }
  }
}

@keyframes overlayin {
  from{ background: rgba(#000, 0); }
  to { background: rgba(#000, 0.6); }
}

@keyframes overlayout {
  from{ background: rgba(#000, 0.6); }
  to { background: rgba(#000, 0); }
}

@keyframes modalin {
  from{ opacity: 0; transform: scale(0.75); }
  to { opacity: 1; transform: scale(1); }
}

@keyframes modalout {
  from{ opacity: 1; transform: scale(1); }
  to { opacity: 0; transform: scale(0.75); }
}
