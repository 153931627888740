.notice {
  background-color: color(tan);
  padding: 1rem;

  &__actions {
    @include breakpoint(sm) {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
    }

    @include breakpoint(xl) {
      flex-direction: row;
      justify-content: center;
      align-items: center
    }
  }

  .button {
    width: 100%;

  }

  &__status {
    padding-bottom: 0.5rem;

    h2 {
      display: inline-flex !important;
      margin: 1rem 0 0.5rem;
    }

    h5 {
      margin: 0;
      text-align: left;
    }

    @include breakpoint(md) {
      border-bottom: 2px solid color(light-grey, 1);

      h2 {
        margin: 2.19rem 0 1rem;
      }

      h5 {
        margin: 2.5rem 0 1rem;
        text-align: right;
      }
    }

    & .label-icon {
      display: inline-flex;
      margin-right: 1rem;
    }
  }
}

