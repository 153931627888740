.form-group {
  margin-bottom: 1em;

  &__label {
    @include pt-sans;
    font-weight: bold;
    display: inline-block;
    padding: 0.25em 0.5em;
    border: 1px solid color(light-grey, 1);
    text-transform: uppercase;
    color: color(dark-grey);
    cursor: pointer;
    margin-bottom: -1px;
  }

  &__container {
    border: 1px solid color(light-grey, 1);
    padding: 0.25em 0.5em;

    .form-text__label {
      overflow-wrap: break-word;
    }
  }
}

.form-container {
  position: relative;

  &--select:before {
    content: '\276E';
    color: color(black);
    transform: translateY(-50%) rotate(-90deg);
    position: absolute;
    right: 0;
    top: 50%;
    pointer-events: none;
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid color(light-grey, 2);
  }

}

.no-clear-ms::-ms-clear{
  display: none;
}

/* .rwd-editor-main = WYSIWYG Editor  */
.form-control, .rdw-editor-main {
  @include form-control-focus;
  @include form-control-disabled;
  width: 100%;
  height: 2.5em;
  padding: 0 0.75em;
  background-color: color(tan);
  border: 1px solid color(light-grey, 2) !important;
  border-radius: 0;
  cursor: pointer;
  color: color(black);

  &::placeholder {
    color: color(dark-grey);
    opacity: 0.6;
  }

  &--large {
    height: 2.875rem;
  }

  &--error {
    border: 2px solid color(red, 1);
  }

  &--radio {
    height: 1.5em;
    width: 1.5em;
  }

  &--select {
    appearance: none;
    padding: 0 3.25em 0 0.75em;

    &::-ms-expand {
      display: none;
    }

    &::-ms-value {
      background-color: inherit;
      color: color(black);
    }

    option {
      padding: 0.25em 0.25em 0.25em 1.25em;
    }
  }

  &--textarea {
    height: auto;
    padding-top: 0.5em;
    /*resize: none;*/
  }

 
  &--filter {
    padding: 0.625rem 0 0.625rem 3rem;
    height: 2.75rem;
  }

  &__search {
    color: color(light-grey, 1);
    height: 1.5rem !important;
    width: 1.5rem !important;
    position: absolute;
    top: 0.625rem;
    left: 1.5rem;
  }

  &__clear {
    color: color(light-grey, 1);
    height: 1.5rem !important;
    width: 1.5rem !important;
    position: absolute;
    top: 0.625rem;
    right: 1.5rem;
  }

  &__clear:hover{
    cursor: pointer;
  }

  &__error-message {
    @include h5;
    margin: 0;
    padding: 0.5rem 0;
    color: color(white);
    background-color: color(red, 1);
    text-align: center;
  }
}





.rightmostsearchform .col .form-control__search{
  color: #6d6d6d;
  height: 1.5rem !important;
  width: 1.5rem !important;
  position: absolute;
  top: 0.625rem;
  left: 1.5rem;
}


input[type="text"].form-control, input[type="password"].form-control {
  &:-moz-ui-invalid {
    box-shadow: none;
  }
}

.form-text {

  &__label {
    @include h4;
    width: 100%;
    margin: 0.625rem 0 0;
  }

  &__value {
    @include h5;
    line-height: 1.15;
    width: 100%;
    margin: 0.625rem 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.multi-select {

  &__results {
    padding: 1rem 0;
  }

  &__result {
    display: inline-block;
    padding: 0.3rem 1.5rem 0.3rem 0.3rem;
    position: relative;
    background-color: color(white);
    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
    border-radius: 20px;
    font-size: 0.75rem;
    border: 1px solid color(light-grey);

    i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;
      font-size: 1rem;
      font-style: normal;
      cursor: pointer;
    }
  }
}

.post-comments {
 
  width: 100%;

  textarea {
   
    min-height: 30px;
    transition: all 200ms;
    outline: none;

    &:focus {
      min-height: 200px;
    }
  }
}

.quickpartbody {
  resize: none;
}
