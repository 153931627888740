.list {
  width: 100%;

  &__head {
    border-bottom: 2px solid color(tan);
    padding-bottom: 0.75rem;
    display: flex;
    align-items: center;
    user-select: none;
  }

  &__row {
    border-top: 1px solid color(tan);
    padding: 0.75rem 0;
    display: flex;
    align-items: center;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 1px solid color(tan);
    }

    &--expanded {
      border: 1px solid color(light-grey, 1);
    }
  }

  &__sharedrow {
    border-top: 1px solid color(tan);
    padding: 0.75rem 0;
    align-items: center;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 1px solid color(tan);
    }

    &--expanded {
      border: 1px solid color(light-grey, 1);
    }
  }


  &__title {
    font-weight: bold;

    & .label-icon {
      margin-right: 1.25rem;
    }

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__icon {
    margin-right: 1.25rem;
  }

  &__archived {
    opacity: 0.5;
  }

  &__meeting-minutes {
    background-color: color(tan);
  }

  &--checklist {
    margin-top: 1rem;
    background-color: color(white);

    h3 {
      margin-bottom: 0.625rem;

      @include breakpoint(md) {
        margin-bottom: 1rem;
      }
    }

    h5 {
      margin-top: 0;
      text-align: left;

      @include breakpoint(md) {
        margin-top: 1.78rem;
        text-align: right;
      }
    }

    &::before {
      content: '';
      display: block;
      height: 1rem;
      width: 100%;
      background-color: color(tan);

      @include breakpoint(md) {
        display: none;
      }
    }
  }

  &--sub-table {
    background-color: color(tan);
    font-size: 0.85rem;

    .list {
      margin: 0;

      &__head {
        background-color: color(light-grey, 1);
        border: 1px solid color(light-grey, 1);
        padding: 0;
        font-weight: 600;
      }

      &__row {
        border: 1px solid color(light-grey, 1);
      }
    }

    .col:first-child {
      padding-left: 3.5rem;
    }
  }

  &__download {
    text-align: right;
    padding: 0;

    @include breakpoint(lg) {
      text-align: left;
      padding: 0 0.75rem;
    }
  }

  &__expand {
    @include h4;
    margin: 0.25rem 0 0;
    font-size: 0.625rem;
    text-align: center;
    text-transform: uppercase;
    color: color(grey);

    &--left {
      text-align: left;
    }
  }

  &__link {
    @include h4;
    margin: 0.5rem 0 0;
    text-decoration: underline;
    color: color(light-grey);
    font-style: italic;
    cursor: pointer;

    svg {
      pointer-events: none;
    }
  }
}

.sort-arrow {
  margin-left: 0.5rem;
  line-height: 1;
  display: inline-block;
  vertical-align: text-top;
}

.sharedFac{
  padding-right: 3px;
  color: blue;
  font-weight:bold ;
 // opacity: 0.5;
  text-decoration: none;
 }
.sharedFacDisable{
  padding-right: 3px;
  color: darkgray;
  font-weight:bold ;
  cursor: not-allowed;
  //opacity: 0.5;
  text-decoration: none;
  pointer-events: none;
}
