.submittal-summary__field-row {
	display: flex;
	padding: 1.25rem 0;
	border-bottom: 2px solid darken(color(tan), 5);
  overflow-wrap: break-word;
  word-wrap: break-word;

	&:last-child {
		border-bottom: 0;
	}
}

.submittal-summary-Header {
	display: flex;
	border-bottom: 2px solid darken(color(tan), 5);
  overflow-wrap: break-word;
	word-wrap: break-word;
	font-weight: 600;
	
	&:last-child {
		border-bottom: 0;
	}


}

.header-border-top
{
	border-top: 5px solid #000;
}

.react-datepicker-popper {
	z-index: 9999 !important;
}
.react-datepicker__time-list{
    overflow-x: hidden !important;
	width: auto  !important;
    padding-left: 0  !important;
}

.react-datepicker__time-list-item
{
    width: 50px !important;
}
