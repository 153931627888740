@use "sass:math";

html,
body {
  box-sizing: border-box;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.seperator{
  margin-bottom: 30px;
    height: 5px;
    background-color: black;
    margin-top: 20px;
}
//for Seperatiors Start(TFS Item 40610)
// #action-required .list__row, #action-required .list--sub-table .list__row:last-child{
//   border-bottom: 5px solid #000;
// }

#action-required .list--sub-table{
  border-bottom: 5px solid #000;
}

#action-required .list__row--expanded, #action-required .list--sub-table .list__row{
  border: 1px solid #e5e8eb;
}
// #contributor-action-required .list__row, #contributor-action-required .list--sub-table .list__row:last-child{
//   border-bottom: 5px solid #000;
// }

#contributor-action-required .list--sub-table{
  border-bottom: 5px solid #000;
}


#contributor-action-required .list__row--expanded, #contributor-action-required .list--sub-table .list__row{
  border: 1px solid #e5e8eb;
}
#action-required .list__head{ padding-top: 1.25rem;}
#contributor-action-required .list__head{ padding-top: 1.25rem;}
#awaiting-assignment .list__head{ padding-top: 1.25rem;}
#all-projects .list__head{ padding-top: 1.25rem;}
#projectfiles-delete .list__head{ padding-top: 1.25rem;}
#notification .list__head{ padding-top: 1.25rem;}

.BoldNotificationHeader{
  font-weight: bold;
}

.BoldNotificationTitle{
  width: 23% !important;
  float: left;
}

.BoldNotificationText{
  width: 55% !important;
  float: right;
}
//for Seperatiors End(TFS Item 40610)
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: ($gutter * 0.5);
  padding-right: ($gutter * 0.5);
  max-width: $max-width;
  width: 100%;

  &--narrow {
    max-width: $max-width * 0.94;
  }
}

.TableLoader
{
  text-align: center;
  vertical-align:middle;
  padding: 20px;
  
}

.EmptyDataSubTable
{
  padding: 5px;
  text-align: center;
  font-weight: bold;
}
.row {
  @include clearfix;
  margin-left: math.div($gutter, -2);
  margin-right: math.div($gutter, -2);
  display: flex;
  flex-wrap: wrap;

  &--center {
    justify-content: center;
  }

  &--middle {
    align-items: center;
  }

  &--space {
    justify-content: space-between;
  }
}

.col {
  padding-left: ($gutter * 0.5);
  padding-right: ($gutter * 0.5);
  flex: 1;
  position: relative;
}


/**
 * Create classes that applies a given width or offset
 */
@each $percentage in $percentages {
  .w-#{floor($percentage)} {
    @include make-width($percentage);

    &.col {
      flex: none;
    }
  }

  .l-#{floor($percentage)} {
    @include make-left($percentage);
  }

  .r-#{floor($percentage)} {
    @include make-right($percentage);
  }
}

.w-auto {
  width: auto;
}

.breakChar{
  word-break: break-word;
}

.w-min-0 {
  min-width: 0;
}

// .w-90{width: 90%;}

/**
 * Create a `.w-{breakpoint}-{percent}` class that
 * applies a given width at a given breakpoint.
 */
@each $key, $value in $breakpoints {

  @include breakpoint($key) {
    .col[class*=w-#{$key}-] {
      flex: none;
    }

    @each $percentage in $percentages {
      .w-#{$key}-#{floor($percentage)} {
        @include make-width($percentage);
      }

      .l-#{$key}-#{floor($percentage)} {
        @include make-left($percentage);
      }

      .r-#{$key}-#{floor($percentage)} {
        @include make-right($percentage);
      }
    }

    .w-#{$key}-auto {
      width: auto;
    }
  }

}

.pull-bottom {
  padding-bottom: 8rem;

  & + * {
    margin-top: -3.5rem;
  }
}

/*
  * Flex styles for all breakpoints
*/
@mixin flexAll($breakpoint: '') {
  .flex#{$breakpoint} {
    display: flex;

    &--wrap {
      flex-wrap: wrap;
    }

    &--no-wrap {
      flex-wrap: nowrap;
    }

    // direction
    &--row {
      flex-direction: row;
    }

    &--col {
      flex-direction: column;
    }

    // justify
    &--justify-start {
      justify-content: flex-start;
    }

    &--justify-center {
      justify-content: center;
    }

    &--justify-end {
      justify-content: flex-end;
    }

    &--justify-space-between {
      justify-content: space-between;
    }

    // align
    &--align-stretch {
      align-items: stretch;
    }

    &--align-center {
      align-items: center;
    }

    &--align-end {
      align-items: flex-end;
    }

    &--align-start {
      align-items: flex-start;
    }

    // children
    &--shrink {
      flex-shrink: 1;
    }

    &--shrink-none {
      flex-shrink: 0;
    }

    &--grow {
      flex-grow: 1;
    }

    &--grow-2 {
      flex-grow: 2;
    }

    &--grow-none {
      flex-grow: 0;
    }

    &--align-self-start {
      align-self: flex-start;
    }

    &--align-self-center {
      align-self: center;
    }

    &--basis--auto {
      flex-basis: auto;
    }

    &--basis-100 {
      flex-basis: 100%;
    }

    // order
    @each $order in $orders {
      &--order-#{$order} {
        order: $order;
      }
    }

    // resets
    &--none {
      flex: none;
    }

    &--block {
      display: block;
    }
  }
}

// flex styles
@include flexAll();

@each $key, $value in $breakpoints {

  @include breakpoint($key) {
    @include flexAll(-#{$key});
  }
}
