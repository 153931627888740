@each $breakpoint, $val in $breakpoints {
  @media (max-width: map-get($breakpoints, #{$breakpoint})) {
    .hide-#{$breakpoint}-down {
      display: none !important;
    }
  }

  @media (min-width: map-get($breakpoints, #{$breakpoint})) {
    .hide-#{$breakpoint}-up {
      display: none !important;
    }
  }
}

.hide-scroll {
  overflow: hidden;
}
