.sidebar {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 1rem;
  background-color: color(brand-dark);
  color: color(white);

  & .label-icon {
    @include size-smallest;

    &--not-submitted--mixed {
      border-width: 1px;
    }

    svg {
      display: none;
    }
  }

  &__back {
    @include h5;
    text-align: center;
    padding: 1.25rem $gutter 1.675rem;
    margin: 0;

    .icon {
      margin-right: 1rem;
    }
  }

  &__logo {
    //width: 2.18rem;
    height: 2.5rem;
    padding: 0 $gutter;
  }

  &__title {
    @include h6;
    margin-top: 2.75rem;
    letter-spacing: 1px;
    padding: 0 $gutter;
  }

  &__item {
    padding: 0.5rem $gutter;
    display: flex;
    align-items: flex-start;

    &--current {
      background-color: color(dark-grey, 0);

      .sidebar__label {
        opacity: 1;
      }
    }
  }

  &__label {
    opacity: 0.4;
    @include h5;
    color: color(white);
    text-decoration: none;
    display: block;
    margin: 0;
    line-height: 1.25em;

    &--approved {
      opacity: 1;
      color: color(white);
    }
  }

  &__badge {
    background-color: color(red);
    width: 1.5rem;
    height: 1.5rem;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    font-size: 0.75rem;
    color: color(white);
  }
}
