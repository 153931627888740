.size-small {
  @include size-small;
}

.size-smaller {
  @include size-smaller;
}

.size-normal {
  @include size-normal;
}

.size-large {
  @include size-large;
}

.size-larger {
  @include size-larger;
}

.size-largest {
  @include size-largest;
}
