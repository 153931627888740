@mixin open-sans {
  font-family: 'Open Sans';
}

@mixin pt-sans {
  font-family: 'PT Sans Narrow';
}

@mixin lh {
  line-height: 1.1;
}

@mixin h1 {
	@include lh;
  @include open-sans;
  font-size: 1.875rem;
	font-weight: 300;
  margin: 1rem 0;
}

@mixin h2 {
	@include lh;
  @include pt-sans;
	font-size: 1.5rem;
	font-weight: 400;
  margin: 2.19rem 0 1rem;
}

@mixin h3 {
	@include lh;
  @include pt-sans;
	font-weight: 700;
	font-size: 1.125rem;
  margin: 1.78rem 0 1rem;
}

@mixin h4 {
	@include lh;
  @include open-sans;
	font-weight: 600;
	font-size: 1rem;
  margin: 1.5rem 0 1rem;
}

@mixin h5 {
	@include lh;
	@include open-sans;
	font-size: 0.875rem;
	font-weight: 400;
  margin: 1.25rem 0 1rem;
	color: color(grey);
}

@mixin h6 {
	@include lh;
	@include pt-sans;
	font-size: 0.75rem;
	font-weight: 700;
  margin: 1rem 0;
}

@mixin title {
  @include lh;
  @include pt-sans;
  font-weight: 700;
  font-size: 1.125rem;
  width: 100%;
  margin: 1.875rem 0 0.75rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid color(light-grey, 2);
  text-transform: uppercase;
}


@mixin titleBrandProject {
  @include lh;
  @include pt-sans;
  font-weight: 700;
  font-size: 1.125rem;
  width: 100%;
  margin: 1.875rem 0 0.75rem;
  padding-bottom: 0.5rem;
  // border-bottom: 1px solid color(light-grey, 2);
  text-transform: uppercase;
}

@mixin paragraph {
	color: color(dark-grey);
  margin: 1rem 0;
}

@mixin small {
  font-size: 87.5%;
}

@mixin smaller {
  font-size: 75%;
}

@mixin large {
  font-size: 110%;
}

@mixin larger {
  font-size: 125%;
}
