.button {
  @include lh;
  color: color(white);
  border: 0;
  background-color: color(brand-blue);
  display: inline-block;
  padding: 0.6rem 0.75rem;
  ;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  font-weight: bold;

  &[disabled] {
    opacity: 0.35;
    cursor: default;
    pointer-events: none;
  }


  &--width-100 {
    width: 100% !important;
  }

  &--outline,
  &--status {
    color: color(blue);
    background-color: color(white);
    border: 2px solid color(light-grey, 1);

    &-inverse {
      color: color(white);
      background-color: color(blue);
      border: 2px solid color(blue);
    }
  }

  &--status {
    @include h4;
    margin: 0;
    text-align: left;
    padding: 0.625rem 1.375rem 0.625rem 4.5rem;

    .label-icon {
      position: absolute;
      left: 1.375rem;
      top: 50%;
      transform: translateY(-50%);
      margin-right: 1.5rem;
    }
  }

  &--back {
    padding: 1rem 3.5rem;
    width: 100% !important;
  }

  &--rightmost {
    // margin-left:auto; 
    margin-right: 0;
  }

  &--scrolltotopForThread {
    position: fixed;
    z-index: 1;
    right: 22%;
  }

  &--scrolltotopForThreadForMobile {
    position: fixed;
    z-index: 1;
    right: 2%;
    top: 16%;
  }

  &--scrolltotop {
    // display: none; /* Hidden by default */
    position: fixed;
    /* Fixed/sticky position */
    bottom: 20px;
    /* Place the button at the bottom of the page */
    right: 30px;
    /* Place the button 30px from the right */
    z-index: 99;
    /* Make sure it does not overlap */
    border: none;
    /* Remove borders */
    outline: none;
    /* Remove outline */
    background-color: #00a8e1;
    /* Set a background color */
    color: white;
    /* Text color */
    cursor: pointer;
    /* Add a mouse pointer on hover */
    padding: 15px;
    /* Some padding */
    border-radius: 10px;
    /* Rounded corners */
    font-size: 18px;
    /* Increase font size */
  }

  &--scrolltotop:hover {
    background-color: #555;
    /* Add a dark-grey background on hover */
  }



  &--icons {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  &--icon-left {
    padding-left: 3.5rem;
  }

  &--icon-right {
    padding-right: 3.5rem;
  }

  &--small {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &--large {
    padding-top: 1.25rem;
    padding-bottom: 1rem;
  }

  &__icon-left {
    position: absolute;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &__icon-right {
    position: absolute;
    right: 0.6rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &__badge {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: color(blue);
    border-radius: 50%;
    font-size: 0.75rem;
    color: color(white);
  }

  &--icon {
    background-color: transparent;
  }

}

.quickpartdeletebutton {
  @include lh;
  color: color(red);
  border: 2px solid black;
  background-color: color(white);
  display: inline-block;
  padding: 0.6rem 0.75rem;
  ;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  font-weight: bold;
}

.quickparttextremovebutton {
  @include lh;
  color: color(black);
  border: 0;
  background-color: color(white);
  display: inline-block;
  padding: 0.6rem 0.75rem;
  ;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  font-weight: bold;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltipSharedStructuretext {
  visibility: hidden;
  width: 160px;
  background-color: #f6f6f6;
  color: #333c4e;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: -300%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.875rem;
  font-family: 'Open Sans';
}



.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.tooltip .tooltipSharedStructuretext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 73%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #f6f6f6 transparent transparent transparent;
}

.tooltip:hover .tooltipSharedStructuretext {
  visibility: visible;
  opacity: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
