// grid gutter
$gutter: 1.5rem;

// container max-width
$max-width: 82rem;

// grid column widths
//$percentages: 100 91.6 80 75 66.6 60 50 40 33.3 30 25 20 8.3;
$percentages: 100 91.6 83.3 75 66.6 58.3 50 41.6 33.3 25 20 19.4 16.6 12.5 8.3 6 0;
$orders: 0 1 2 3 4 5;

// responsive breakpoints
$breakpoint-xs: 24rem;
$breakpoint-sm: 40rem;
$breakpoint-md: 54rem;
$breakpoint-lg: 64rem;
$breakpoint-xl: 80rem;

// array of breakpoints
$breakpoints: (
  xs: $breakpoint-xs,  // 384
  sm: $breakpoint-sm,  // 640
  md: $breakpoint-md,  // 864
  lg: $breakpoint-lg,  // 1024
  xl: $breakpoint-xl   // 1280
);

$slide-out-modal-width: 45rem;
$slide-out-modal-sidebar-width: 60rem;
$slide-out-modal-button-width: 2rem;
