.slide-out-modal {
  @include z-10;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate3d(0, 0, 0);

  &[aria-disabled="true"] {
    transform: translate3d(100vw, 0, 0);
  }

  &__container {
    padding: 1em;

    @include breakpoint(md) {
      padding: 2rem 4.25rem;
    }
  }

  &__content {
    @include z-1;
    background-color: color(white);
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: $slide-out-modal-width;
    top: 0;
    right: 0;
  }

  &__backdrop {
    @include z-0;
    cursor: pointer;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: color(black);
    opacity: 0.6;

    &--hidden {
      opacity: 0;
    }
  }

  &__content-overflow {
    height: 100%;
    overflow-y: auto;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 10;
  }

  &__link {
    @include h4;
    margin: 0.5rem 0 0;
    text-decoration: underline;
    color: color(light-grey);
    font-style: italic;
    cursor: pointer;

    &--resent {
      text-decoration: none;
      font-style: normal;
      cursor: default;
    }
  }

  &--sidebar .slide-out-modal {

    &__container {
      padding: 0 2.5rem 2.5rem;
    }

    &__content {
      max-width: 100%;

      @include breakpoint(md) {
        max-width: 66.6666667%;
      }
    }

    &__close-btn {
      position: absolute;
      top: 10rem;
      right: -1.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 100%;
     // background-color: color(yellow, 1);
      background-color: color(white);
      color: color(black);
      box-shadow: 0 2px 6px 0 rgba(0,0,0,0.25);
      cursor: pointer;
    }
  }

  &--small-header .slide-out-modal {

    &__container {
      padding: 0 1rem;

      @include breakpoint(md) {
        padding: 0 2.375rem;
      }
    }

    &__auth-container {
      padding: 4.5rem 0;
    }

    &__text-content {
      background-color: color(tan);
      margin: 0 -1.75rem;
      padding: 0.01rem 0.75rem 2.125rem;

      .title {
        margin-bottom: 0;
      }

      .titleBrandProject
      {
        margin-bottom: 0;
      }

      @include breakpoint(md) {
        margin: 0 -3.125rem;
      }
    }

    &__confirm-button {
      width: 100%;
      margin: 4rem -1.75rem 0;
      padding: 1.5rem 1.75rem;
      background-color: color(brand-dark);
      box-sizing: content-box;

      @include breakpoint(md) {
        margin: 4rem -3.125rem 0;
        padding: 1.5rem 3.125rem;
      }
    }

    &__clear-filters {
      text-decoration: underline;
      font-style: italic;
      color: color(light-grey);
      cursor: pointer;
    }

    &__add-contributor, &__clear-filters {
      background-color: color(tan);
      margin-top: 1rem;
      padding: 1.125rem 0.5rem 0.75rem;

      &-cancel {
        text-decoration: underline;
        font-style: italic;
        color: color(light-grey);
        cursor: pointer;
      }
    }

    &__delete-filter {
      text-decoration: underline;
      font-style: italic;
      color: color(light-grey);
      cursor: pointer;

    }

  }
}
