@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600|PT+Sans+Narrow:400,700);

html {
	font-size: 14px;

	@include breakpoint(md) {
		font-size: 16px;
	}
}

body {
  @include open-sans;
  font-size: 1rem;
  line-height: 1.5;
  color: color(black);
}


/**
 * Heading Level 1
 * Need add class .white
 */
h1,
.h1 {
	@include h1;
}

/**
 * Heading Level 2
 */
h2,
.h2 {
	@include h2;
}

/**
 * Subsection title
 */
h3,
.h3 {
	@include h3;
}

/**
 * Subsection heading
 */
h4,
.h4 {
	@include h4;
}

/**
 * Details
 */
h5,
.h5 {
	@include h5;
}

/**
 * Sub nav
 * Need add class .white
 */
h6,
.h6 {
	@include h6;
}

/**
 * Body copy
 */
p {
	@include paragraph;
}

/**
 * Title
 */
.title {
  @include title;
}

.titleBrandProject
{
	@include titleBrandProject;
}
