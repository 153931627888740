@keyframes slide-in {
  300% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
  300% { -webkit-transform: translateX(0%); }
}
  
@keyframes slide-out {
  0% { transform: translateX(0%); }
  300% { transform: translateX(300%); }
}

@-webkit-keyframes slide-out {
  0% { -webkit-transform: translateX(0%); }
  300% { -webkit-transform: translateX(300%); }
}

.notificationstatus
{
    &__modal {
        display:block;
        position: fixed;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.25);
        width:25%;
        padding:2px;
        top:0px;
       
        transform: translateX(300%);
        -webkit-transform: translateX(300%);

        &--modal_content {  
            background-color: #fff;
            border: 2px solid #e5e8eb;
            position: absolute;
            padding: 5px;
            text-align: center;
            


          }
          &--notification_color{
            font-size:small;
            font-family: 'Open Sans';
            color:#f00000!important
          }

        
       
      }

      &-slide-in{
        animation: slide-in 0.5s reverse;
        -webkit-animation: slide-in 0.5s reverse;
      }
      &-slide-out {
        animation: slide-out 0.5s reverse;
        -webkit-animation: slide-out 0.5s reverse;
    }
     
      &-close {
        color: Black;
        float: right;

        &:hover {
          color:red;
          cursor: pointer;
        }
      }


     
      
}
