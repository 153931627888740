.modal-header {
  @include h5;
  position: relative;
 // background-color: color(brand-brown);
  background-color: color(brand-blue);
  margin: 0;
  color: color(white);
  height: 12.5rem;

  &__container {
    margin: 0 2.5rem 0 4.25rem;
  }

  &--small-tabs {
    height: 6.25rem;
  }

  &--small {
    height: 4.69rem;

    .modal-header__container {
      margin-left: 2.375rem;
    }
  }

  &__row {
    height: 6.25rem;
    padding: 0 1.25em;

    &--content {
      @include h4;
      margin: 0 -0.75rem;
      padding: 0 1.5rem;

      @include breakpoint(md) {
        padding: 0 4.25rem;
      }
    }

    .list__title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .small {
      color: color(grey);
    }

    @include breakpoint(md) {
      padding: 0 2.5rem 0 4.25rem;
    }
  }

  &__close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 100%;
    background-color: color(white, 0);
    color: color(black);
    cursor: pointer;
  }
}
