// border classes
@each $direction in top right bottom left {
  .b#{unquote(str_slice($direction, 1, 1))} {
    border-#{$direction}: thin solid currentColor;
  }
}
.border-none {
	border: none;
}

