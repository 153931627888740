.hidden {
  display: none;
}

.o-hidden {
  overflow: hidden;
}

.v-hidden {
  visibility: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline-flex {
  display: inline-flex;
}

.flex {
  display: flex;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.flex-column {
  @include flex(column);
}

.flex-end {
  @include flex(column, flex-end, flex-start);
}

.flex-end-item {
  @include flex(column, flex-end, flex-end)
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-right {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-align-right {
  align-items: flex-end !important;
}

.flex-wrap {
  @include flex;
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1 0 auto;
}
