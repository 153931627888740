.z-0 {
  @include z-0;
}

.z-1 {
  @include z-1;
}

.z-2 {
  @include z-2;
}

.z-3 {
  @include z-3;
}

.z-4 {
  @include z-4;
}

.z-5 {
  @include z-5;
}

.z-6 {
  @include z-6;
}

.z-7 {
  @include z-7;
}

.z-8 {
  @include z-8;
}

.z-9 {
  @include z-9;
}

.z-10 {
  @include z-10;
}
