.auth {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  background: url("../images/background2.png");
  background-size: cover;

  h1 {
    color: color(white);
    text-align: center;
  }

  h3 {
    margin-top: 1.125rem;
    margin-bottom: 0.625rem;
  }

  h4 {
    margin: 1.25rem 0 1rem;
  }

  .col {
    padding: 0.6rem 0.5rem 0;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__content {
    max-width: 45rem;
    width: 100%;
    padding: 0 0.75rem;
  }

   &__message {
    width: 100%;
    border-radius: 2px;
    padding: 0 1rem;
    margin: 0.625rem 0 0;
    background-color: color(tan);
    opacity: 0.9;
  }

  &__error {
    color: #ff0000;
    font-weight: bold;
    width: 100%;
    border-radius: 2px;
    padding: 0 1rem;
    margin: 0.625rem 0 0;
    background-color: color(tan);
    opacity: 0.9;

    &--narrow {
      max-width: 30em;
    }
  }

  &__form {
    width: 100%;
    border-radius: 2px;
    padding: 0 1rem;
    margin-top: 1.5rem;
    background-color: rgba(color(tan), 0.8);
  }

  & &__forget {
    @include h5;
    margin: 1rem 0;
    text-align: center;
    padding: 0;

    a {
      color: color(blue);
    }

    &--login {
      margin: 1.5rem 0;
      padding: 0.6rem 0.5rem 0;
    }

    &--password {
      @include breakpoint(sm) {
        margin: 1.5rem 0;
        text-align: left;
        padding: 0.6rem 0.5rem 0;
      }
    }



  }
}
