.confirm-box {
	@include z-11;
  	position: fixed;
  	top: 0;
  	right: 0;
  	bottom: 0;
  	left: 0;
  	transform: translate3d(0, 0, 0);

  	&__backdrop {
	    @include z-0;
	    cursor: pointer;
	    height: 100%;
	    width: 100%;
	    position: fixed;
	    top: 0;
	    background-color: color(black);
	    opacity: 0.6;
  	}

  	&__container {
  		@include z-1;
  		position: absolute;
  		top: 50%;
  		left: 50%;
  		transform: translate(-50%, -50%);
  		background-color: color(white);
  		padding-bottom: 3rem;
  		width: 90%;
  		background-color: color(white);

  		@include breakpoint(md) {
  			width: 20%;
  		}
  	}

	  &__editContainer {
		@include z-1;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: color(white);
		padding-bottom: 3rem;
		width: 90%;
		background-color: color(white);

		@include breakpoint(md) {
			width: 50%;
		}
	}
}