.popup-menu {
  cursor: pointer;

  &__cover {
    @include z-9;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__item {
    @include z-10;
    padding: 0.25rem 1rem;
    border-bottom: 1px solid color(light-grey, 1);
    min-height: 2.5rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: color(black);

    &:first-child {
      border-top-right-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
    }

    a {
      text-decoration: none;
      width: 100%;
    }

    .label-icon {
      margin-right: 1rem;
    }

    .icon--upload {
      @include size-large;
      margin-right: 1rem;
      color: color(blue);
    }

    &:hover {
      background-color: color(tan);
    }

    &--disabled {
      background-color: color(light-grey, 1);
      cursor: auto;

      .label-icon {
        background-color: color(grey)
      }

      &:hover {
        background-color: color(light-grey, 1);
      }
    }
  }

  &__block {
    @include z-10;
    position: absolute;
    background-color: color(white);
    display: flex;
    flex-direction: column;
    border: 1px solid color(light-grey, 2);
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;

    // top

    &--top-left,
    &--top-center,
    &--top-right {
      padding-bottom: 0.6rem;

      & .popup-menu__item {
        border-bottom: 1px solid color(light-grey, 1);
      }

      &:after {
        @include z-10;
        pointer-events: none;
        position: absolute;
        top: 100%;
        content: " ";
        height: 0;
        width: 0;
        border: solid rgba(136, 183, 213, 0);
        border-top-color: color(white);
        border-width: 0.5rem;
        margin-left: -0.5rem;
      }
    }

    &--top-left:after {
      right: 0.5rem;
    }

    &--top-center:after {
      left: 50%;
    }

    &--top-right:after {
      left: 1rem;
    }

    // bottom

    &--bottom-left,
    &--bottom-center,
    &--bottom-right {
      padding-top: 0.6rem;

      .popup-menu__item {
        border-top: 1px solid color(light-grey, 1);
        border-bottom: none;
        border-radius: 0;

        &:nth-last-child(2) {
          border-bottom-right-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }
      }

      &:after {
        @include z-10;
        pointer-events: none;
        position: absolute;
        bottom: 100%;
        content: " ";
        height: 0;
        width: 0;
        border: solid rgba(136, 183, 213, 0);
        border-bottom-color: color(white);
        border-width: 0.5rem;
        margin-left: -0.5rem;
      }
    }

    &--bottom-left:after {
      right: 0.5rem;
    }

    &--bottom-center:after {
      left: 50%;
    }

    &--bottom-right:after {
      left: 1rem;
    }
  }
}
