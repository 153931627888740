.google-translate {
	@include flex(row, flex-start, center);
	padding: 0 0.75rem;
	background-color: color(tan);
	margin: 0 -1rem;

	

	&__lbl {
		font-size: 1rem;
		text-transform: uppercase;
		font-weight: bold;
		font-family: 'PT Sans Narrow', sans-serif;
		color: color(light-grey);
		margin-right: 0.5rem;
		display: none;

		@include breakpoint(md) {
			display: inline-block;
		}
	}

	&__element {
		width: 100%;

		.goog-te-gadget {
			@include flex(column, center, center);
			font-size: 0.85rem;

			.goog-logo-link {
				font-size: 0.85rem;
			}
			
			img {
				margin: 0 4px -2px 8px;
				width: 55px;
				height: 20px;
			}

			@include breakpoint(sm) {
				@include flex(row, flex-start, center);
			}

			div:first-child {				
				position: relative;

				@include breakpoint(sm) {
					margin-right: auto;
				}

				&:before {
					content: '\276E';
					display: inline-block;
					color: color(black);
					position: absolute;
					top: 50%;
					right: 10px;
					font-size: 1rem;
					transform: translateY(-50%) rotate(-90deg);
				}
			}
		}

		.goog-te-combo {
			appearance: none;
			border: none;
    		box-shadow: none;
    		padding: 0.5rem 0.75rem;
    		font-size: 1.15rem;
    		background-color: color(tan);
    		text-transform: capitalize;

    		option {
    			text-transform: capitalize;
    		}
		}
		.goog-te-combo {
			&::-ms-expand {
    			display: none !important;
			}
		}
	}
}