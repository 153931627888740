.label {
  @include lh;
  padding: 0.6rem 0.75rem;
  color: color(white);
  display: inline-block;
  border-radius: 2px;
  text-align: left;

  &--white {
    color: color(black);
    border: 1px solid color(light-grey, 1);
    background-color: color(white);
  }
}

.label-icon {
  @include size-large;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &--accepted-w-comments {
    background-color: color(green);
    color: color(white);
  }

  &--approved {
    background-color: color(green);
    color: color(white);
  }

   &--shared {
    background-color: color(brand-blue);
    color: color(white);
  }

  &--pending {
    background-color: color(yellow);
    color: color(white);
  }

  &--revisions {
  /*  background-color: color(red); */
    color: color(white);
    background: url("../images/icons/pending-review.svg");
    background-size: cover;
  }

  &--revisions--mixed {
    background-color: color(yellow);
    background-image: linear-gradient(transparent 50%, color(red) 50%);
  }

  &--not-submitted {
    background-color: color(white);
    border: 2px solid color(light-grey, 1);

    &--mixed {
      background-color: color(white);
      border: 2px solid color(light-grey, 1);
      background-image: linear-gradient(transparent 50%, color(green) 50%);
    }
  }

  &--meeting-minutes .icon {
    @include size-larger;
    color: color(blue);
  }

  &--archived .icon {
    @include size-larger;
  }

  &--expand {
    @include size-largest;
    background-color: color(black);
    color: color(white);
    cursor: pointer;
  }
}
