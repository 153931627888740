@mixin form-control-focus($color: color(light-grey, 1)) {

	&:focus {
		border-color: $color;
		outline: 0;
		box-shadow: 0 0 0.5em rgba($color, 0.6);
	}
}

@mixin form-control-disabled($color: color(light-grey)) {

	&[disabled], &[readonly] {
		background-color: $color;
		opacity: 1;
		cursor: not-allowed;
	}
}
