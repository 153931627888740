.progressbar {
	   display: flex;
    margin-top: 1rem;

    &__body {
    	width: 100%;
    	height: 3.125rem;
    	background-color: color(tan);
    	padding: 0.5rem;
    	border: 1px solid color(light-grey, 2);
    }
    

    &__cover {
      width: 100%;
      height: 100%;
      background-color: color(white);
      overflow: hidden;
    }

    &__value {
      background-color: color(green);
      color: color(white);
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 1.25rem;
    }
}