.simple-modal {
	@include z-11;
  	position: fixed;
  	top: 0;
  	right: 0;
  	bottom: 0;
  	left: 0;
  	transform: translate3d(0, 0, 0);

  	&.is-hidden {
    	transform: translate3d(100vw, 0, 0);
  	}

  	&__backdrop {
	    @include z-0;
	    cursor: pointer;
	    height: 100%;
	    width: 100%;
	    position: fixed;
	    top: 0;
	    background-color: color(black);
	    opacity: 0.6;

	    &--hidden {
	      opacity: 0;
	    }
  	}

  	&__container {
  		@include z-1;
  		position: absolute;
  		top: 50%;
  		left: 50%;
  		transform: translate(-50%, -50%);
  		background-color: color(white);
      overflow-y: auto;
      max-height: 100%;

  		&--medium {
  			width: 95%;
  			
  			@include breakpoint(md) {
  				width: 50%;
  			}
  		}
  	}

  	&__content {
      position: relative;
  		padding: 1rem 2rem 5rem;
      height: 100%;
  	}

  	&__footer {
  		width: 100%;
  		position: absolute;
  		bottom: 0;
  		left: 0;
  		right: 0;
  	}
}