.global-header {
  @include h5;
  position: relative;
 // background-color: color(brand-brown);
  background-color: color(brand-blue);
  margin: 0;
  color: color(white);

  &__logo {
    display: flex;
    align-items: center;
    height: 2.5rem;
    white-space: nowrap;

    img {
      display: inline-block;
      vertical-align: middle;
      height: 1.75rem;
      margin-right: 1.25rem;
    }
  }

  &__menu {
    height: 2.5rem;
    display: none;
    align-items: center;
    justify-content: flex-end;

    @include breakpoint(md) {
      display: flex;
    }

    &-item {
      padding: 0 0.65rem;
    }

    a {
      opacity: 0.75;
      text-decoration: none;
      color: color(white);
    }

    &--current {
      opacity: 1 !important;
    }
  }

  &__user {
    height: 2.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;

    &-name {
      color: color(white);
    }

    &-avatar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      width: 2.25rem;
      height: 2.25rem;
      margin-left: 0.25rem;
      border-radius: 100%;
      background-color: color(white);
     // color: color(brand-brown);
      color: color(black);

      @include breakpoint(md) {
        margin-left: 1.625rem;
      }
    }

    .icon {
      margin-left: 0.5rem;
      opacity: 0.4;
      cursor: pointer;
    }
  }

  &__project {
    height: 6.25rem;
    margin: 0.5em 0;

    &-separator {
      height: 3rem;
      width: 2px;
      background-color: color(white);
    //  opacity: 0.2;
      margin: 0 1.25rem;
    }

    &-label {
     /* display: flex; */
      align-items: center;
    }

    img {
      max-height: 100%;
    }
  }

  &__dropdown {
    margin-left: 1rem;
    opacity: 0.2;
    cursor: pointer;
  }

  &__tab {
    @include h4;
    font-size: 0.75em;
    margin: 0; // update margin from h4
    display: inline-flex;
    align-items: flex-end;

    &--item {
      margin-right: 1rem;
      display: inline-flex;

      @include breakpoint(md) {
        margin-right: 2rem;
      }
    }

    &--item-link {
      display: block;
      text-decoration: none;
      text-transform: uppercase;
      color: color(white);
      padding: 0.55rem 0 1rem;
      border-bottom: 4px solid color(brand-blue);
      cursor: pointer;
    }

    &--item-link a {
      color: color(white);
      text-decoration: none;
    }

    &--badge {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      height: 1.5rem;
      width: 1.5rem;
      color: color(black);
     // background-color: color(tan, 2);
      background-color: color(white);
      margin-left: 0.5rem;
      margin-bottom: 0.9rem;
      border-radius: 100%;
    }

    &--current {
     // border-bottom: 4px solid color(tan, 1) !important;
      border-bottom: 4px solid color(white, 1) !important;
      opacity: 1 !important;
    }
  }

  // mobile version
  &__mobile {
    //height: 8rem;
    min-height: 8rem;
    display: flex;
    align-items: center;

    &-menu, &-close {
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
    }

    &-close {
      display: none;
    }

    &-list {
      display: none;
    }

    &-label {
      @include h1;
      font-size: 1.125rem;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }

    &-add {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .global-header__dropdown {
      margin-left: 0;
      opacity: 1;
    }
  }

  .sidebar {
    display: none;
  }

  // hamburger menu
  @media (max-width: $breakpoint-md) {
    &__menu--toggle {
      display: none;
      padding-left: $gutter;
      padding-right: $gutter;
    }

    &__mobile-ticker:checked {

      & ~ .global-header__menu--toggle {
        display: flex;

        .global-header {
          &__menu {
            display: flex !important;
            flex-wrap: wrap;
            width: 100%;
            height: auto;

            & .global-header__menu-item {
              @include h4;
              margin: 0;
              padding: 1rem 0;
              //background-color: color(brand-brown);
              background-color: color(brand-blue);
              font-size: 1.125rem;
              width: 100%;
             // border-bottom: 1px solid color(brand-brown, 1);
              border-bottom: 1px solid color(brand-blue, 1);

              &:last-child {
                border-bottom-width: 0;
              }
            }
          }

          &__mobile-menu {
            display: none;
          }

          &__mobile-close {
            display: flex;
          }
        }
      }

      &~.sidebar {
        display: block;
      }
    }
  }

  .container {
    padding: 0;
  }

  @include breakpoint(md) {
    .container {
      padding-left: 1.75rem;
      padding-right: 1.75rem;
    }
  }
}
