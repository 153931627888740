.pagination {
  border-top: 2px solid color(light-grey, 1);
  padding: 2rem 0.75rem 0.75rem;
  margin-top: 2rem;
  width: 100%;
  text-align: right;
  user-select: none;

  @include breakpoint(md) {
    text-align: right;
  }

  &__container {
    display: flex;
    justify-content: flex-end;

    @include breakpoint(md) {
      justify-content: flex-end;
    }
  }

  &__item {
    cursor: pointer;
    margin: 0 0.4rem;

    &--page {
      color: color(grey);
      font-size: 0.875em;
      padding: 0.2em 0.75em;
      text-align: center;
      background-color: color(tan);
      border: 1px solid color(light-grey, 1);
    }

    &--active {
      background-color: color(brand-dark);
      color: color(white);
    }

    &--arrow {
      margin: 0;

      & .icon {
        height: 0.82rem;
        width: 1rem;
      }
    }
  }

  &__label {
    font-size: 0.75em;
    margin-top: 1em;
  }
}
