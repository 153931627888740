.badge {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  font-size: 0.75rem;
 // background-color: color(brand-brown);
  background-color: color(brand-blue);
  color: color(white);

  &--round {
    border-radius: 100%;
  }
}