.status-popup {
	position: absolute;
	top: -1.5rem;
	left: 50%;
	transform: translate(-50%, -100%);
	background-color: color(white);
	min-width: 30rem;
	padding: 1rem 0 0;
	border: 1px solid color(light-grey, 1);
	z-index: 2;

	&__back {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
	}

	&:before, &:after {
		content: "";
		display: block;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 100%);
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 20px 20px 0 20px;
		border-color: color(light-grey, 1) transparent transparent transparent;
		z-index: 1;
	}

	&:after {
		border-color: color(white) transparent transparent transparent;
		z-index: 2;
		bottom: 2px;
	}

	&__row {
		border-bottom: 2px solid color(light-grey, 1);
		display: flex;
		padding: 1rem;

		&:last-child {
			border-bottom: none;
		}
	}

	&__category {
		@include flex(row, flex-start, center);
		position: relative;
		font-weight: 600;
		font-size: 0.9rem;
		cursor: pointer;
	}

	&__subitems {
		&.accepted {
			.status-popup__subitem:before {
				background-color: color(green);
			}
		}

		&.action-required {
			.status-popup__subitem:before {
				background-color: color(red);
			}
		}

		&.pending {
			.status-popup__subitem:before {
				background-color: color(yellow);
			}
		}
	}

	&__subitem {
		@include flex(row, flex-start, center);
		position: relative;
		font-weight: 600;
		font-size: 0.8rem;
		margin-bottom: 0.4rem;
		cursor: pointer;

		&:before {
			content: '';
			display: inline-block;
			width: 0.75rem;
			height: 0.75rem;
			border-radius: 50%;
			margin-right: 0.5rem;
		}
	}

	&__wrapper {
		display: inline-block;
		position: relative;
		padding-bottom: 5px;
	}
}